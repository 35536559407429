<template>
	<div class="centersss">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="消息中心"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		right-text="全部已读"
		right-arrow
		@click-right="onClickright"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<div v-if="type=='list' || type==''">
			<div class="center_middle" v-for="v,index in messageList" :key="index" v-on:click="enterMessage(v)">
				<div class="center_middle_nav">
					<div class="center_middle_setup">
						<div>
							{{v.title}}
							<div class="remind" v-if="v.if_read != '1'"></div>
						</div>
						<div style="color:#999999;">{{v.message_date_show}}</div>
					</div>
					<div class="center_middle_list">
						<div class="center_middle_content">
							<span>{{v.short_content}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="type=='info'" v-on:click="closeMessageInfo">
			<div class="message-info">
				<div class="message-info-title">{{messageInfo.title}}</div>
				<div class="message-info-time">{{messageInfo.message_date_show}}</div>
				<div class="message-info-content">{{messageInfo.content}}</div>
			</div>
		</div>
		<div v-if="messageList.length == 0" class="custom-image"><img :src="image" alt=""><div>暂无消息</div></div>
		<!-- <van-empty v-if="messageList.length != 0" class="custom-image" :image-size="[100,60]" :image="image" description="暂无消息" /> -->
	</div>
</template>

<script>
import { Dialog } from 'vant';
	export default {
		data() {
			return {
				userinfo: {},
				messageList: [],
				unixTime: 0,
				type: 'list',
				messageInfo: {},
				pageTitle: '消息中心',
				image:require('../../assets/user_edit/icon_kong.png'),
			};
		},
		mounted() {
			this.getuserinfo()
			this.getMessageList()
			this.unixTime = Math.floor(Date.now() / 1000);
		},
		methods: {
			enterMessage(messageInfo) {
				this.type = 'info'
				this.pageTitle = '';
				this.messageInfo = messageInfo;
				if(messageInfo.if_read != '1'){
					this.readMessage()
				}
			},
			closeMessageInfo() {
				this.type = 'list'
				this.pageTitle = '消息中心'
				this.getMessageList()
			},
			async readMessage() {
				const {
					data: res
				} = await this.$http.post('/api/scsj/read_message',{
					id:this.messageInfo.id
				})
				if (res.code == 1) {}
			},
			onClickright(){
				Dialog.confirm({
					title: '全部已读',
					message: '确定将全部消息设为已读吗？',
				})
					.then(async () => {
						const {
					data: res
				} = await this.$http.post('/api/scsj/read_message',{
					
				})
				if (res.code == 1) {
					this.getMessageList()
				}
					})
					.catch(() => {
						
					});
				
			},
			// 用户详情
			async getuserinfo() {
				const {
					data: res
				} = await this.$http.post('/api/user/get_userinfo_1_5_1')
				if (res.code == 1) {
					this.userinfo = res.data.res
				}
			},
			async getMessageList() {
				const {
					data: res
				} = await this.$http.post('/api/scsj/get_message_list')
				if (res.code == 1) {
					// this.messageList = res.data.res
					this.messageList = []
					res.data.res.forEach((v) => {
						v.message_date_show = this.intervalTime(v.msg_time);
						this.messageList.push(v)
					})
				}
			},
			intervalTime(msg_time) {
				let interval_time = this.unixTime - msg_time;
				if (interval_time > 24 * 60 * 60) {
					return this.parseTime(msg_time, '{m}月{d}日');
				}
				if (interval_time > 1 * 60 * 60) {
					return parseInt(interval_time/3600) + '小时前'
				}
				if (interval_time > 1 * 60) {
					return parseInt(interval_time/60) + '分钟前'
				}
				return '现在'
				
			},
			parseTime(time, cFormat) {
				const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
				let date
				if (typeof time === 'object') {
					date = time
				} else {
					if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
						time = parseInt(time)
					}
					if (typeof time === 'number' && time.toString().length === 10) {
						time = time * 1000
					}
					date = new Date(time)
				}
				const formatObj = {
					y: date.getFullYear(),
					m: date.getMonth() + 1,
					d: date.getDate(),
					h: date.getHours(),
					i: date.getMinutes(),
					s: date.getSeconds(),
					a: date.getDay()
				}
				const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
					let value = formatObj[key]
					if (key === 'a') {
						return ['日', '一', '二', '三', '四', '五', '六'][value]
					}
					// if (result.length > 0 && value < 10) {
					//   value = '0' + value
					// }
					return value || 0
				})
				return timeStr
			}
		}
	}
</script>

<style lang="scss" scoped>
.centersss{


	.message-info {
		min-height: 1000px;
		margin-top: 50px;
		margin-left: 30px;
		margin-right: 30px;
		margin-bottom: 10px;
		background: #222222;
		font-size: 32px;
		font-family: HYQiHeiY3-EEW, HYQiHeiY3;
		font-weight: normal;
		color: #BBBBBB;
		line-height: 44px;
		padding-top: 50px;

		.message-info-title {
			margin-top: 20px;
			font-size: 40px;
			font-family: HYQiHeiY3-EEW, HYQiHeiY3;
			font-weight: normal;
			color: #ECECEC;
			line-height: 40px;
			text-align: center;
		}

		.message-info-time {
			margin-top: 20px;
			font-size: 28px;
			font-family: HYQiHeiY3-DEW, HYQiHeiY3;
			font-weight: normal;
			color: #999999;
			line-height: 28px;
			text-align: center;
		}

		.message-info-content {
			margin-top: 70px;
			font-size: 32px;
			font-family: HYQiHeiY3-EEW, HYQiHeiY3;
			font-weight: normal;
			color: #BBBBBB;
			line-height: 44px;
			text-align: center;
		}
	}

	.remind {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #FA335E;
		margin-bottom: 10px;
		margin-left: 3px;
	}

	.centersss {
		position: relative;

		.center_bj {
			width: 100%;
			position: absolute;
			z-index: -1;
			top: 0;
		}
	}

	.center_middle {
		padding: 0 30px 40px;
		
		z-index: 2;

		.center_middle_nav {
			padding: 36px;
			box-sizing: border-box;
			background: linear-gradient(135deg, #29282B 0%, #29282B 100%);
			border-radius: 12px;
			// height: 200px;
			color: #ffffff;

			.center_middle_setup {
				font-weight: normal;
				font-family: HYQiHeiY3-55;
				font-size: 36px;
				display: flex;
				justify-content: space-between;
			}

			.center_middle_list {
				display: flex;

				.center_middle_content {
					flex-flow: column;
					align-items: center;
					margin-top: 50px;
					width: 550px;
					height: 40px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					span {
						font-size: 28px;
						font-family: HYQiHeiY3-45;
					
					}
				}
			}
		}
	}

 .custom-image {
	padding: 50px 0;
	margin-top: 200px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	div{
		margin-top: 20px;
		color: #ECECEC;
		opacity: 0.5;
	}
	img{
		width: 280px;
		height: 180px;
	}

 }
}
</style>
